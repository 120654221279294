export const businessManagerNav = [
    {
      name: 'Dashboard',
      url: '/business-manager/dashboard',
      icon: "assets/icons/star.svg",
    },
    {
      name: 'Reports',
      url: '/business-manager',
      icon: "assets/icons/star.svg",
      children: [
        {
          name: 'Sales Report',
          url: '/business-manager/sales-report',
          icon: 'icon-cart'
        },
        {
          name: 'Service Report',
          url: '/business-manager/service-report',
          icon: 'icon-cart'
        },
        {
          name: 'Recharge Report',
          url: '/business-manager/recharge-report',
          icon: 'icon-cart'
        },
        {
          name: 'Expense Report',
          url: '/business-manager/expense-report',
          icon: 'icon-cart'
        }
      ]
    }
  ];
  