export const branchNavItems = [
  {
    name: "Home",
    url: '/dashboard',
    icon: "assets/icons/star.svg",
  },
  {
    name: 'Dashboard',
    url: '/branch-views/dashboard',
    icon: "assets/icons/dashboard.svg",
  },
  // {
  //   name : "Tallent Pay",
  //   url : '/branch-views/tallent-pay',
  //   icon : 'icon-star',
  // },
  {
    name: 'Manage Stocks',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [

    
      {
        name: 'View Stock',
        url: '/branch-views/view-stock',
        icon: 'icon-cart'
      },
      
      {
        name: 'Stock Check',
        url: '/branch-views/stock-check',
        icon: 'icon-cart'
      },

    ]
  },
  {
    name: 'Manage Service',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Add Service',
        url: '/branch-views/service',
        icon: 'icon-cart'
      },
      {
        name: 'View Job Cards',
        url: '/branch-views/view-mobile-service',
        icon: 'icon-cart'
      },

    ]
  }, {
    name: 'Manage Purchase',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Add Purchase',
        url: '/branch-views/add-purchase',
        icon: 'icon-cart'
      },
      {
        name: 'View Purchases',
        url: '/branch-views/view-purchase',
        icon: 'icon-cart'
      },
    ]
  }, {
    name: 'Manage Accounts',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Accounts Closing',
        url: '/branch-views/accounts-closing',
        icon: 'icon-cart'
      },
      {
        name: 'Accounts History',
        url: '/branch-views/accounts-history',
        icon: 'icon-cart'
      },
    ]
  }, {
    name: 'Billing Service',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Billing',
        url: '/branch-views/billing',
        icon: 'icon-star',
      },
      {
        name: 'Purchase Return',
        url: '/branch-views/billing/purchase-return',
        icon: 'icon-star',
      },
      {
        name: 'Franchise',
        url: '/branch-views/billing/franchise',
        icon: 'icon-star'
      },
      {
        name: 'Retailer',
        url: '/branch-views/billing/retailer',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: 'Brand Model Report',
        url: '/branch-views/model-report',
        icon: 'icon-cart',
      },
      {
        name: 'Stock Value Report',
        url: '/branch-views/report/stock-value',
        icon: 'icon-cart',
      },
      {
        name: 'Invoice Report',
        url: '/branch-views/invoice-report',
        icon: 'icon-cart',
      },
      {
        name: 'Invoice Detail',
        url: '/branch-views/invoice-detail',
        icon: 'icon-cart',
      },
      {
        name: 'Invoice Profit',
        url: '/branch-views/invoice-Profit',
        icon: 'icon-cart',
      },
      // {
      //   name: 'Move Stock Report',
      //   url: '/branch-views/move-stock-reports',
      //   icon: 'icon-cart',
      // },
      {
        name: 'Sales Report',
        url: '/branch-views/sales-report',
        icon: 'icon-cart'
      },
      {
        name: 'Service Report',
        url: '/branch-views/service-report',
        icon: 'icon-cart'
      },
      {
        name: 'Recharge Report',
        url: '/branch-views/recharge-report',
        icon: 'icon-cart'
      },
      {
        name: "Recharge Detail Report",
        url: '/branch-views/recharge-service',
        icon: 'icon-cart'
      },
      {
        name: 'Expense Report',
        url: '/branch-views/expense-report',
        icon: 'icon-cart'
      },
      {
        name: 'Status Report',
        url: '/branch-views/status-report',
        icon: 'icon-cart'
      }
    ]
  },

  {
    name: 'View Customer',
    url: '/branch-views/view-customers',
    icon: "assets/icons/star.svg",
  },
  {
    name: 'Manage Expenses',
    url: '/branch-views',
    icon: "assets/icons/star.svg",
    children: [
      {
        name: "Add Expenses",
        url: "/branch-views/expenses",

      },
      {
        name: 'View Expenses',
        url: "/branch-views/view-expenses",

      }
    ]
  },
  {
    name: "Change Password",
    url: "/branch-views/change-password",
    icon: "assets/icons/star.svg",
  },



];
