export const storeNavItems = [
    {
        name: "Home",
        url: '/dashboard',
        icon: "assets/icons/star.svg",
    }, {
        name: 'Dashboard',
        url: '/store-views/dashboard',
        icon: "assets/icons/dashboard.svg",
        badge:
        {
            variant: 'info',

        }
    },

    {
        name: 'Manage Reports',
        url: '/store-views',
        icon: "assets/icons/star.svg",
        children: [
            {
                name: 'Stock Report',
                url: '/store-views/view-stocks',
                icon: 'icon-cart'
            },
            {
                name: 'Stock Value Report',
                url: '/store-views/report/stock-value',
                icon: 'icon-cart',
            },
            {
                name: 'Performance',
                url: '/store-views/report/performance',
                icon: 'icon-cart',
            },
            {
                name: 'Sales Report',
                url: '/store-views/sales-person-report',
                icon: 'icon-cart',

            },
            {
                name: 'Recharge Report',
                url: '/store-views/recharge-report',
                icon: 'icon-cart'
            },
            {
                name: 'Recharge Detail Report',
                url: '/store-views/view-recharge-detail',
                icon: 'icon-cart'
            },

            {
                name: 'Sevice Report',
                url: '/store-views/service-report',
                icon: 'icon-cart'
            },
            {
                name: 'Sevice Detail Report',
                url: '/store-views/view-mobile-detail',
                icon: 'icon-cart'
            },

            {
                name: 'Invoice Report',
                url: '/store-views/invoice-report',
                icon: 'icon-cart'
            },
            {
                name: 'Invoice Detail',
                url: '/store-views/invoice-detail',
                icon: 'icon-cart'
            },
           
            {
                name: 'Expense Report',
                url: '/store-views/view-expense',
                icon: 'icon-cart'
            },
            {
                name: 'Expense Detail Report',
                url: '/store-views/view-expense-detail',
                icon: 'icon-cart'
            },
            {
                name: 'Customer Report',
                url: '/store-views/view-customers',
                icon: 'icon-cart'
            },
            {
                name: 'GST Report',
                url: '/store-views/tax-report',
                icon: 'icon-cart'
            }
        ]
    },
    {
        name: 'Master Data',
        url: '/store-views',
        icon: 'icon-star',
        children: [
            {
                name: 'Category',
                url: '/store-views/meta-data/category',
                icon: 'icon-cart'
            },
            {
                name: 'Sub-Category',
                url: '/store-views/sub-category',
                icon: 'icon-cart'
            },
            {
                name: 'Brand',
                url: '/store-views/brand',
                icon: 'icon-cart'
            },
            {
                name: 'Model',
                url: '/store-views/model',
                icon: 'icon-cart'
            },
            {
                name: 'Color',
                url: '/store-views/color',
                icon: 'icon-cart'
            },
            {
                name: 'GST',
                url: '/store-views/gst',
                icon: 'icon-cart'
            },
            {
                name: 'Payment Method',
                url: '/store-views/paymentMethod',
                icon: 'icon-cart'
            },
            {
                name: 'Expenses',
                url: '/store-views/meta-data/expense',
                icon: 'icon-cart'
            },

            {
                name: 'Sales Person',
                url: '/store-views/salesPerson',
                icon: 'icon-cart'
            },
            {
                name: 'Service Issues',
                url: '/store-views/issue',
                icon: 'icon-cart'

            },
            {
                name: 'Operator',
                url: '/store-views/operator',
                icon: 'icon-cart'

            },
            {
                name: "Suppliers",
                url: "/store-views/suppliers",
                icon: 'icon-cart'
            }, {
                name: "Closing Stock",
                url: "/store-views/meta-closing",
                icon: 'icon-cart'
            }
        ]
    },

    {
        name: 'Manage Branch',
        url: '/store-views',
        icon: 'icon-star',
        children: [
            {
                name: 'Add Branch',
                url: '/store-views/add-branch',
                icon: 'icon-cart'
            },
            {
                name: 'View Branch',
                url: '/store-views/view-branch',
                icon: 'icon-cart'
            },
        ]
    },
    // {
    //     name: "Transactions",
    //     url: "/store-views/transactions",
    //     icon: "assets/icons/star.svg",
    // },
    {
        name: "Change Password",
        url: "/store-views/change-password",
        icon: "assets/icons/star.svg",
    }
];


// {
//     name: 'Sales Reports',
//     url: '/store-views/report',
//     icon: 'icon-cart'
// },